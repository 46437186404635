import * as React from "react";
import { Box, Button, Img, Text, useToast, Tooltip } from "@chakra-ui/react";
import FileUploadAnimation from "../../img/FileUploadAnimation.gif";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";

export const FileUpload = (props: any) => {
	const [currentPlan] = useSelector((state: any) => [state.subscriptionPlan]);
	const [files, setFiles] = React.useState<File[]>([]);
	const toast = useToast();

	const onDrop = React.useCallback(
		(acceptedFiles: any, fileRejections: any) => {
			if (fileRejections.length > 0) {
				let isLargeFile = 0,
					fileNotSupported = 0;
				fileRejections.forEach((file: any) => {
					file.errors.forEach((err: any) => {
						if (err.code === "file-too-large") {
							isLargeFile++;
						}
						if (err.code === "file-invalid-type") {
							fileNotSupported++;
						}
					});
				});

				if (isLargeFile > 0 && fileNotSupported == 0) {
					toast({
						title:
							isLargeFile === 1
								? "Whoa, that's a heavyweight file! 🏋️ ACE loves to flex its muscles, but this file is a bit too bulky for us. Please keep it under 50MB for a smooth lift-off!"
								: "Whoa, that's a heavyweight file! 🏋️ ACE loves to flex its muscles, but some of the files are a bit too bulky for us. Please keep it under 50MB for a lift-off!",
						variant: "left-accent",
						status: "error",
						position: "top-right",
						isClosable: false,
					});
				} else if (isLargeFile == 0 && fileNotSupported > 0) {
					toast({
						title:
							fileNotSupported === 1
								? "ACE Alert: Your file format isn’t yet part of our revolution. Please upload a .STEP or .STP file to proceed"
								: "ACE Alert: Some of your files are not yet part of our revolution. Please ensure all uploaded files are in either .STEP or .STP format.",
						variant: "left-accent",
						status: "error",
						position: "top-right",
						isClosable: false,
					});
				} else {
					toast({
						title:
							"Some of the files you uploaded are either greater than 50MB or not of .STEP/.STP file format.",
						variant: "left-accent",
						status: "error",
						position: "top-right",
						isClosable: false,
					});
				}
			}
			if (
				acceptedFiles.length >
				currentPlan?.allocated_file_uploads - currentPlan?.used_file_uploads
			) {
				toast({
					title: `You can upload only ${
						currentPlan?.allocated_file_uploads - currentPlan?.used_file_uploads
					} files. Upgrade your plan now!`,
					variant: "left-accent",
					status: "error",
					position: "top-right",
					isClosable: true,
				});
			} else if (acceptedFiles.length > 0) {
				props.handleFileUpload(acceptedFiles);
				setFiles(acceptedFiles);
			}
		},
		[props]
	);

	const {
		getRootProps,
		getInputProps,
		open,
		fileRejections,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		onDrop,
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		multiple: true,
		accept: {
			"application/STEP": [".stp", ".STP", ".step", ".STEP"],
		},
		minSize: 10,
		maxSize: 52428799,
		disabled:
			currentPlan?.plan_status === "Expired" ||
			currentPlan?.used_file_uploads == currentPlan?.allocated_file_uploads,
	});

	return (
		<Box
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
			py="3rem"
			w="100%"
			bg="#0087ee30"
			border="1px dashed #0087ee"
			gap="0.5rem"
			borderRadius="lg"
			{...getRootProps()}
		>
			<Img src={FileUploadAnimation} alt="file-upload-animation" />
			<Text onClick={open}>
				<span style={{ fontWeight: "500" }}>Drag and drop</span> files to
				upload, or
			</Text>
			<Tooltip
				label={
					currentPlan?.plan_status === "Expired" ||
					currentPlan?.used_file_uploads == currentPlan?.allocated_file_uploads
						? "Your Limit Exceeded/Expired, Please Upgrade Now!"
						: ""
				}
			>
				<Button
					className="aceFileUploadInitiateBtn"
					id="ace_file_upload_initiate_btn"
					fontWeight={"600"}
					color="#fff"
					bgColor="#0087ee"
					_hover={{ bg: "#006DC0" }}
					onClick={open}
					isDisabled={
						currentPlan?.plan_status === "Expired" ||
						currentPlan?.used_file_uploads ==
							currentPlan?.allocated_file_uploads
					}
				>
					Get Instant Cost
				</Button>
			</Tooltip>
			<input {...getInputProps({})} />
			<Text fontWeight="500">
				{"File types: STEP, STP | File size: < 50 MB"}
			</Text>
			<Text color="#00000050" fontSize="12px">
				All your files are entirely secured with us{" "}
				<span style={{ color: "0087ee" }}>(read our privacy guarantee)</span>
			</Text>
		</Box>
	);
};
